import { FC } from "react";
import { ClientSessions } from "@practice/sdk";
import classNames from "classnames";
import pluralize from "pluralize";

import { displayNameFromContact } from "@lib/contacts";

import ClientAvatar from "@components/Client/ClientAvatar";
import InfoFilledIcon from "@components/Icons/InfoFilledIcon";
import { Tooltip, TooltipSize } from "@components/Tooltips/Tooltip";

import CountSessionsOrCycles from "../CountSessionsOrCycles";
import { formatValueCurrency } from "../utils";

interface BillingClientItemProps {
  clientSessions: ClientSessions;
  toggleClient: (client: ClientSessions) => void;
  isOpen?: boolean;
}

type Package = ClientSessions["packages"][0];

const tableStyles = {
  wrapper: "grid grid-cols-6 gap-1 text-grey-500 text-sm",
  date: "text-left",
  description: "col-span-5 text-left",
};

const BillingClientItem: FC<BillingClientItemProps> = ({
  clientSessions,
  toggleClient,
  isOpen = false,
}) => {
  const { client, packages } = clientSessions;

  const renderPackageHeader = () => {
    const totalPackages = packages.length;

    return (
      <div className="flex items-center space-x-2 truncate shrink-0">
        <p className="flex-1 text-sm text-grey-500 underline truncate max-w-[120px]">
          {`${totalPackages} ${pluralize("package", totalPackages)}`}
        </p>
      </div>
    );
  };

  const renderPackageDetails = (item: Package) => {
    return (
      <>
        <p className="text-green-200 font-medium">{item.title}</p>
      </>
    );
  };

  const renderPackage = (packageItem: Package) => {
    return (
      <div key={packageItem.id} className="grid gap-1">
        {renderPackageDetails(packageItem)}
        {packageItem.sessionsGroupedByRate.map((item, key) => (
          <div
            className="flex flex-col border border-grey-900 bg-white rounded-lg items-center justify-center"
            key={`package-${packageItem.id}-rate-${key}`}
          >
            <div className="mt-5">
              <CountSessionsOrCycles
                label="hour"
                total={item.quantity}
                showIcon
              />
              <div>
                <p className="font-medium text-xl">
                  {formatValueCurrency(item.amount, "USD", 2)}
                </p>
                <div className="flex items-center text-sm text-grey-500">
                  <p>{`${formatValueCurrency(item.rate, "USD")} per hour`}</p>
                  <Tooltip
                    size={TooltipSize.small}
                    contentClassName="px-3 py-1 !text-xs"
                    trigger={<InfoFilledIcon className="w-4 h-4" />}
                  >
                    {item.title}
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="w-full px-4">
              <div className={classNames(tableStyles.wrapper, "border-b mt-3")}>
                <p className={tableStyles.date}>Date</p>
                <p className={tableStyles.description}>Description</p>
              </div>
              {item.transactions?.map((t, k) => (
                <a
                  className={classNames(
                    tableStyles.wrapper,
                    "divide-y my-1 pr-1"
                  )}
                  key={`${t.id}-${k}`}
                  target="_blank"
                  href={`/appointments/${t.artifact.id}`}
                >
                  <span className="border-none">
                    {new Date(t.date).getMonth() + 1}/
                    {new Date(t.date).getDate()}
                  </span>
                  <span className="col-span-5 border-none">
                    {t.artifact.title}
                  </span>
                </a>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDetails = () => {
    if (!isOpen) return null;

    return (
      <div className="p-4 pt-0 grid gap-4">
        {packages.map((item) => renderPackage(item))}
      </div>
    );
  };

  const renderClient = (
    <div
      className="flex items-center justify-between space-x-2 px-4 py-3"
      onClick={() => toggleClient(clientSessions)}
    >
      <div className="flex items-center space-x-2 font-medium text-black-ink text-sm truncate">
        <ClientAvatar className="!m-0" client={client} size="xsmall" />
        <span className="truncate">{displayNameFromContact(client)}</span>
      </div>

      {renderPackageHeader()}
    </div>
  );

  return (
    <div
      key={client.id}
      className={classNames("hover:bg-grey-950", isOpen && "bg-grey-950")}
    >
      {renderClient}
      {renderDetails()}
    </div>
  );
};

export default BillingClientItem;
